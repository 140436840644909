import { reset } from 'numeral'
import { Vue, Component } from 'nuxt-property-decorator'

export interface Keyframe {
  duration: number
  delay: number
  classes: any
}

@Component
export class AnimationScheduler extends Vue {
  /*
    This mixin manages chained css animations by setting the required classes of certain components 
    in timestamps defined by each keyframe object delay and duration.
  */
  private _keyframes!: Keyframe[]
  private _runOnce!: boolean
  private _animationClasses!: any
  private _animationIndex!: number
  private _isRunning!: boolean

  private resetClasses() {
    for (let key of Object.keys(this._animationClasses))
      this._animationClasses[key] = ''
  }

  private setClasses(classesToSet: any) {
    if (classesToSet === 'reset') {
      this.resetClasses()
      return
    }
    // inplace merge  animationClasses contained in classesToSet
    for (let key of Object.keys(classesToSet))
      this._animationClasses[key] = classesToSet[key]
  }
  private mainLoop() {
    // performs each keyframe in it's required timestamp
    if (!this._isRunning) {
      this.resetClasses()
      return
    }
    const keyframe = this._keyframes[this._animationIndex]
    
    // "wait" for delay then execute the mainloop iteration
    setTimeout(() => {
      this.setClasses(keyframe.classes)
      this._animationIndex++
      if (this._animationIndex === this._keyframes.length) {
        if (this._runOnce) {
          this._isRunning = false
          return
        }
        this._animationIndex = 0
      }
      // "wait" for animation to end and then repeat the process
      setTimeout(this.mainLoop,keyframe.duration)
    }, keyframe.delay)
  }
  /*
    Starts the animation scheduler based in some keyframes and a object containing the required classes to change.

    @param {Keyframe[]} keyframes - List of keyframes objects to be executed in the required timestamp
    @param {any} animationClasses - JS object containing the state of the CSS classes that must change during the animation
    @param {boolean} runOnce - Flag that indicates if animation should be played only once (default false)

    @public
  */
  startAnimationScheduler(
    keyframes: Keyframe[],
    animationClasses: any,
    runOnce = false
  ) {
    if(this._isRunning) return
    this._keyframes = keyframes
    this._animationClasses = animationClasses
    this._runOnce = runOnce

    this._animationIndex = 0
    this._isRunning = true
    this.mainLoop()
  }
  /*
    Stops the animation scheduler in the next scheduled animation or reset classes if animation has already stopped
  */
  stopAnimationScheduler() {
    if (this._keyframes==null) return
    if (this._isRunning == false) this.resetClasses()
    this._isRunning = false
  }
}
