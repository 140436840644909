



















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
  name: 'BlkTriangle',
})
export default class extends Vue {
  /*
    This component renders a SVG triangle given a proportion size, a stroke color and a fill color.
  */
  @Prop({ required: true, type: Number }) size!: Number
  @Prop({ default: 2, type: Number }) thickness!: Number
  @Prop({ default: 'none', type: String }) strokeColor!: string
  @Prop({ default: 'none', type: String }) fillColor!: string
}
