














import { Vue, Component } from 'nuxt-property-decorator'
import BlkMain from '~/components/landing/BlkMain.vue'
import HighlightBanner from '~/components/landing/HighlightBanner.vue'
import BlkWhoWeAre from '~/components/landing/BlkWhoWeAre.vue'
import BlkHowItWorks from '~/components/landing/BlkHowItWorks.vue'
import BlkInMedia from '~/components/landing/BlkInMedia.vue'
import BlkOurOpportunities from '~/components/landing/BlkOurOpportunities.vue'
import BlkWhy from '~/components/landing/BlkWhy.vue'
import BlkOurTeam from '~/components/landing/BlkOurTeam.vue'
import BlkTestimonials from '~/components/landing/BlkTestimonials.vue'
import BlkFaqSection from '~/components/landing/BlkFaqSection.vue'
import BlkWarning from '~/components/landing/BlkWarning.vue'

@Component({
  name: 'LandingPage',
  components: {
    HighlightBanner,
    BlkMain,
    BlkWhoWeAre,
    BlkHowItWorks,
    BlkInMedia,
    BlkOurOpportunities,
    BlkWarning,
    BlkWhy,
    BlkOurTeam,
    BlkTestimonials,
    BlkFaqSection,
  },
  auth: false,
})
export default class extends Vue {
  mounted() {
    this.$loadingScreen.show = false
    const referralCode = this.$route.query.referral_code
    if (referralCode)
      localStorage.setItem('referral_code', String(referralCode))
  }
}
