













































































import { Vue, Component } from 'vue-property-decorator'
import BlkTriangle from '~/components/BlkTriangle.vue'
import BlkGraph from '~/components/landing/BlkGraph.vue'

interface ReasonType {
  title: string
  text: string
}

@Component({
  name: 'BlkWhy',
  components: {
    BlkTriangle,
    BlkGraph
  }
})
export default class extends Vue {
  reasons: Array<ReasonType> = [
    {
      title: this.$root.$tc('landing.whyInvest.reason1.title'),
      text: this.$root.$tc('landing.whyInvest.reason1.text')
    },
    {
      title: this.$root.$tc('landing.whyInvest.reason2.title'),
      text: this.$root.$tc('landing.whyInvest.reason2.text')
    },
    {
      title: this.$root.$tc('landing.whyInvest.reason3.title'),
      text: this.$root.$tc('landing.whyInvest.reason3.text')
    },
  ]
}
