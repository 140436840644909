
























import { Component, Vue } from 'nuxt-property-decorator'
interface StepCard {
  title: string
  description: string
  imgName: string
  alt: string
}
@Component({
  name: 'BlkHowItWorks',
  components: {}
})
export default class extends Vue {
  steps: StepCard[] = [
    {
      title: this.$root.$t('landing.howWorks.step1.title').toString(),
      description: this.$root.$t('landing.howWorks.step1.text').toString(),
      imgName: 'step1.svg',
      alt: this.$root.$t('landing.altText.step1').toString()
    },
    {
      title: this.$root.$t('landing.howWorks.step2.title').toString(),
      description: this.$root.$t('landing.howWorks.step2.text').toString(),
      imgName: 'step2.svg',
      alt: this.$root.$t('landing.altText.step2').toString()
    },
    {
      title: this.$root.$t('landing.howWorks.step3.title').toString(),
      description: this.$root.$t('landing.howWorks.step3.text').toString(),
      imgName: 'step3.svg',
      alt: this.$root.$t('landing.altText.step3').toString()
    }
  ]
}
