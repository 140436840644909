





























import { Component, Vue } from 'nuxt-property-decorator'
interface JournalInfo {
  title: string
  description: string
  url: string
  logoName: string
  logoWidth: string
  logoAlt: string
}
@Component({
  name: 'BlkInMedia',
  components: {}
})
export default class extends Vue {
  journals: JournalInfo[] = [
    {
      title: this.$root.$t('landing.media.veja.title').toString(),
      description: this.$root.$t('landing.media.veja.text').toString(),
      logoName: 'veja-logo.svg',
      url:
        'https://veja.abril.com.br/economia/fintech-que-distribuira-r-100-milhoes-em-precatorios-anuncia-1a-rodada/',
      logoWidth: '69px',
      logoAlt: this.$root.$t('landing.altText.veja').toString()
    },
    {
      title: this.$root.$t('landing.media.estadao.title').toString(),
      description: this.$root.$t('landing.media.estadao.text').toString(),
      logoName: 'estadao-logo.svg',
      url:
        'https://einvestidor.estadao.com.br/mercado/investimentos-alternativos-2021/',
      logoWidth: '124px',
      logoAlt: this.$root.$t('landing.altText.estadao').toString()
    },
    {
      title: this.$root.$t('landing.media.exame.title').toString(),
      description: this.$root.$t('landing.media.exame.text').toString(),
      logoName: 'exame-logo.svg',
      url:
        'https://exame.com/invest/ativos-alternativos-se-popularizam-e-chegam-ao-pequeno-investidor/',
      logoWidth: '124px',
      logoAlt: this.$root.$t('landing.altText.exame').toString()
    }
  ]
}
