






import { Component, Vue } from 'nuxt-property-decorator'
@Component({
  name: 'BlkSlide',
})
export default class extends Vue {}
