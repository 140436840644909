






























import { Component, Vue } from 'nuxt-property-decorator'
@Component({
  name: 'BlkWarning'
})
export default class extends Vue {
  mounted() {
    // @ts-ignore
    this.$refs['warning-modal'].show()
  }
}
