<template>
  <div v-if="!$apollo.loading && highlightBanner" class="highlight-banner">
    <div class="highlight-banner__title">
      {{ highlightBanner.title }}
    </div>
    <nuxt-link class="highlight-banner__link" :to="highlightBanner.linkUrl">
      {{ highlightBanner.linkLabel }}
    </nuxt-link>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import Vue from 'vue'

export default Vue.extend({
  name: 'HighlightBanner',
  apollo: {
    highlightBanner: {
      query: gql`
        {
          highlightBanner {
            title
            linkLabel
            linkUrl
          }
        }
      `
    }
  }
})
</script>
<style scoped lang="scss">
.highlight-banner {
  @apply w-full py-4 font-semibold text-black text-center bg-secondary-yellow;
  &__title {
    @apply text-base md:text-xl mb-2.5 px-6;
  }
  &__link {
    @apply text-sm md:text-base uppercase text-black;
    letter-spacing: 0.12em;
  }
}
</style>
