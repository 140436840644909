



































































import { Component, Vue, mixins } from 'nuxt-property-decorator'
import { AnimationScheduler, Keyframe } from '~/mixins/animationScheduler'
@Component({
  name: 'BlkGraph'
})
export default class extends mixins(AnimationScheduler) {
  animationClasses = {
    gradient: '',
    line: '',
    arrow: '',
    caption: ''
  }
  observer: any
  keyframes: Keyframe[] = [
    {
      delay: 0,
      duration: 500,
      classes: { line: 'line-grow-in', gradient: 'gradient-slide-in' }
    },
    { delay: 500, duration: 800, classes: { arrow: 'arrow-grow-in' } },
    { delay: 300, duration: 300, classes: { caption: 'caption-fade-in' } }
  ]
  mounted() {
    this.observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        //@ts-ignore
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          if (newValue.match('aos-animate')) {
            this.startAnimationScheduler(
              this.keyframes,
              this.animationClasses,
              true
            )
          } else {
            this.stopAnimationScheduler()
          }
        })
      }
    })

    this.observer.observe(this.$refs.graphContainer, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class']
    })
  }
  beforeDestroy() {
    this.observer.disconnect()
  }
}
