













































































import { Component, Vue } from 'nuxt-property-decorator'
import BlkTriangle from '~/components/BlkTriangle.vue'
import BlkFallbackedImg from '../BlkFallbackedImg.vue'

@Component({
  name: 'BlkWhoWeAre',
  components: {
    BlkTriangle,
    BlkFallbackedImg,
  }
})
export default class extends Vue {}
