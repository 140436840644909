







































































import { Component, Vue } from 'nuxt-property-decorator'
import BlkTriangle from '~/components/BlkTriangle.vue'
import BlkMainGallery from '~/components/landing/BlkMainGallery.vue'
@Component({
  name: 'BlkMain',
  components: { BlkTriangle, BlkMainGallery }
})
export default class extends Vue {
  goToLogin() {
    this.$auth.loginWith('auth0', {
      params: { signup: true }
    })
  }
}
