


























































import { Vue, Component } from 'vue-property-decorator'
import BlkCarousel from '~/components/BlkCarousel.vue'
import BlkSlide from '~/components/BlkSlide.vue'
import BlkTriangle from '~/components/BlkTriangle.vue'
import BlkFallbackedImg from '../BlkFallbackedImg.vue'

interface User {
  name: string
  profession: string
  testimonial: string
  pictureName: string
}
@Component({
  name: 'BlkTestimonials',
  components: {
    BlkCarousel,
    BlkSlide,
    BlkTriangle,
    BlkFallbackedImg,
  },
})
export default class extends Vue {
  users: User[] = [
    {
      name: 'Marcelo Carraresi',
      profession: this.$root.$tc('landing.testimonials.test1.occupation'),
      testimonial: this.$root.$tc('landing.testimonials.test1.text'),
      pictureName: 'Marcelo-Carraresi-1',
    },
    {
      name: 'Alexandre Rahal',
      profession: this.$root.$tc('landing.testimonials.test2.occupation'),
      testimonial: this.$root.$tc('landing.testimonials.test2.text'),
      pictureName: 'Alexandre-Rahal-2',
    },
    {
      name: 'Christian Laubenheimer',
      profession: this.$root.$tc('landing.testimonials.test3.occupation'),
      testimonial: this.$root.$tc('landing.testimonials.test3.text'),
      pictureName: 'Christian-Laubenheimer-2',
    },
  ]
}
