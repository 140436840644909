




















import { Component, Vue, mixins } from 'nuxt-property-decorator'
import { AnimationScheduler, Keyframe } from '~/mixins/animationScheduler'
import BlkFallbackedImg from '~/components/BlkFallbackedImg.vue'

@Component({
  name: 'BlkMainGallery',
  components:{
    BlkFallbackedImg
  }
})
export default class extends mixins(AnimationScheduler) {
  animationClasses = {
    image1: '',
    image2: '',
    blackRect: '',
    greenRect1: '',
    greenRect2: ''
  }
  keyframes: Keyframe[] = [
    {
      duration: 350,
      delay: 8000,
      classes: { greenRect1: 'green-rect1-slide-out' }
    },
    {
      duration: 350,
      delay: 0,
      classes: { blackRect: 'black-rect-slide-out' }
    },
    {
      duration: 800,
      delay: 0,
      classes: { image1: 'fade-out' }
    },
    {
      duration: 800,
      delay: 300,
      classes: { image2: 'fade-in' }
    },
    {
      duration: 350,
      delay: 0,
      classes: { greenRect2: 'green-rect2-slide-in' }
    },
    {
      duration: 350,
      delay: 8000,
      classes: { greenRect2: 'green-rect2-slide-out' }
    },
    {
      duration: 800,
      delay: 0,
      classes: { image2: 'fade-out' }
    },
    {
      duration: 800,
      delay: 300,
      classes: { image1: 'fade-in' }
    },
    {
      duration: 350,
      delay: 0,
      classes: { blackRect: 'black-rect-slide-in' }
    },
    {
      duration: 350,
      delay: 0,
      classes: { greenRect1: 'green-rect1-slide-in' }
    }
  ]

  created() {
    this.startAnimationScheduler(this.keyframes, this.animationClasses)
  }
}
