




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
@Component({
  name: 'BlkCarousel',
  components: {}
})
export default class extends Vue {
  /* This component wraps inner BlkSlide components to create a carousel with given
  slides per view (slidesPV) and horizontal space between each item (spacing),
  the props (slidesPV|spacing)Breakpoints set each parameter on breakpoints following
  tailwind's default breakpoints sm (640px), md (768px), lg (1024px), xl (1280) and 2xl (1536px).
  */

  @Prop({ required: true }) name!: string
  @Prop({ default: true }) pagination!: boolean
  @Prop({ default: true }) paginationButton!: boolean
  @Prop({ default: 1, type: Number }) slidesPV!: number
  @Prop({ default: 0, type: Number }) spacing!: number
  @Prop({ default: () => [], type: Array }) slidesPVBreakpoints!: number[]
  @Prop({ default: () => [], type: Array }) spacingBreakpoints!: number[]
  swiperOption: any = {
    slidesPerView: this.slidesPV,
    spaceBetween: this.spacing,
    pagination: this.pagination
      ? {
          el: '.swiper-pagination',
          clickable: true
        }
      : {},
    navigation: this.paginationButton
      ? {
          prevEl: '#' + this.prevPaginationButtonId,
          nextEl: '#' + this.nextPaginationButtonId
        }
      : {},
    breakpoints: this.swiperBreakpoints
  }
  get swiperBreakpoints() {
    if (!(this.slidesPVBreakpoints || this.spacingBreakpoints)) return undefined
    return [640, 768, 1024, 1280, 1536].reduce<any>(
      (obj: any, breakpoint: number, index: number) => {
        obj[breakpoint] = {
          slidesPerView: this.slidesPVBreakpoints[index],
          spaceBetween: this.spacingBreakpoints[index] || 0
        }
        return obj
      },
      {}
    )
  }
  mounted() {
    //@ts-ignore
    const mySwiper = this.mySwiper
    if (mySwiper && this.$listeners.slideChange) {
      mySwiper.on('slideChange', () =>
        this.$emit('slideChange', mySwiper.realIndex)
      )
    }
  }
  get prevPaginationButtonId() {
    return `swiper-button-prev-${this.name}`
  }
  get nextPaginationButtonId() {
    return `swiper-button-next-${this.name}`
  }
}
