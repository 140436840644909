// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("static/images/left-arrow.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("static/images/right-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".blk-carousel{margin:auto;width:100%}.blk-carousel .swiper-pagination{position:static;display:flex;align-items:center;justify-content:center;padding-top:.75rem;padding-bottom:.75rem}.blk-carousel .swiper-pagination:focus{outline:2px solid transparent;outline-offset:2px}@media (min-width:768px){.blk-carousel .swiper-pagination{padding-top:2rem;padding-bottom:2rem}}.blk-carousel .swiper-pagination-bullet{margin:unset!important;margin-right:12px!important;background-color:#bdbdbd;opacity:1;width:8px;height:8px}.blk-carousel .swiper-pagination-bullet-active{background-color:#71f69c!important;width:12px;height:12px}@media (min-width:768px){.blk-carousel .swiper-pagination-bullet{margin-right:14px!important;width:10px;height:10px}.blk-carousel .swiper-pagination-bullet-active{width:16px;height:16px}}.blk-carousel .swiper-wrapper{width:0}.swiper-button-prev{position:static;display:none;height:2.5rem;width:5rem;align-self:center}.swiper-button-prev:focus{outline:2px solid transparent;outline-offset:2px}@media (min-width:768px){.swiper-button-prev{display:block}}.swiper-button-prev{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:25px;background-repeat:no-repeat;background-position:50%}.swiper-button-next{position:static;display:none;height:2.5rem;width:5rem;align-self:center}.swiper-button-next:focus{outline:2px solid transparent;outline-offset:2px}@media (min-width:768px){.swiper-button-next{display:block}}.swiper-button-next{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:25px;background-repeat:no-repeat;background-position:50%}.swiper-button-next:after,.swiper-button-prev:after{content:none}", ""]);
// Exports
module.exports = exports;
