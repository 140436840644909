





























import { defineComponent, computed } from '@nuxtjs/composition-api'
import getOfferBannerImg from '@/utils/getOfferBannerImg'
import BlkOffersCard from '@/components/offers/BlkOffersCard.vue'
import BlkCarousel from '~/components/BlkCarousel.vue'
import { orderBy, groupBy, cloneDeep } from 'lodash'
import BlkSlide from '~/components/BlkSlide.vue'
import BlkTriangle from '~/components/BlkTriangle.vue'
import { offersQuery, OfferType } from '~/queries/offersQuery'
import { useQuery, useResult } from '@/composables/blkApollo'

export default defineComponent({
  name: 'BlkOurOpportunities',
  components: {
    BlkCarousel,
    BlkSlide,
    BlkTriangle,
    BlkOffersCard
  },
  setup() {
    const { result, loading } = useQuery(offersQuery, null, {
      fetchPolicy: 'cache-first'
    })
    const offers = useResult<OfferType[], OfferType[]>(result, [])

    const allOffers = computed(() => {
      const formattedOffers = cloneDeep(offers.value).map((offer) => ({
        ...offer,
        closingDate: new Date(offer.closingDate),
        openingDate: new Date(offer.openingDate)
      }))

      const groups = groupBy(formattedOffers, (offer) => offer.status)

      const sortedOffers = (
        status: string,
        field: string,
        order: 'asc' | 'desc',
        size: number
      ) => orderBy(groups[status] || [], [field], [order]).slice(0, size)

      const openOffers = sortedOffers('open', 'closingDate', 'asc', 4)
      const preOpenOffers = sortedOffers('preopen', 'openingDate', 'asc', 4)
      const closedOffers = sortedOffers('closed', 'closingDate', 'desc', 3)
      const paidOffers = sortedOffers('paid', 'irr', 'desc', 3)

      return openOffers
        .concat(preOpenOffers)
        .concat(paidOffers)
        .concat(closedOffers)
    })

    return { getOfferBannerImg, allOffers, loading }
  }
})
