






























































import { Vue, Component } from 'vue-property-decorator'
import BlkCarousel from '~/components/BlkCarousel.vue'
import BlkSlide from '~/components/BlkSlide.vue'
import BlkTriangle from '~/components/BlkTriangle.vue'
import BlkFallbackedImg from '../BlkFallbackedImg.vue'

interface TeamMemberType {
  name: string
  position: string
  pictureName: string
  linkedinLink: string
  altText: string
}
@Component({
  name: 'BlkOurTeam',
  components: {
    BlkCarousel,
    BlkSlide,
    BlkTriangle,
    BlkFallbackedImg
  }
})
export default class extends Vue {
  teamMembers: TeamMemberType[] = [
    {
      name: 'Caio Fasanella',
      position: 'Founder & CEO',
      pictureName: 'caio',
      linkedinLink: 'https://www.linkedin.com/in/caiofasanella/',
      altText: this.$root.$tc('landing.altText.caio')
    },
    {
      name: 'Beatriz Dib Nami',
      position: 'Head of Legal',
      pictureName: 'beatriz',
      linkedinLink: 'https://www.linkedin.com/in/beatriz-dib-nami-55a930121/',
      altText: this.$root.$tc('landing.altText.bia')
    },
    {
      name: 'Theo Ribeiro',
      position: 'Founder & CTO / CPO',
      pictureName: 'theo',
      linkedinLink: 'https://www.linkedin.com/in/theoribeiro/',
      altText: this.$root.$tc('landing.altText.theo')
    },

    {
      name: 'Pablo Staubli',
      position: 'Founder & COO',
      pictureName: 'pablo',
      linkedinLink: 'https://www.linkedin.com/in/pablo-staubli/',
      altText: this.$root.$tc('landing.altText.pablo')
    },
    {
      name: 'Clara Dalanora',
      position: 'Head of Product Marketing',
      pictureName: 'clara',
      linkedinLink: 'https://www.linkedin.com/in/claradalanora/',
      altText: this.$root.$tc('landing.altText.clara')
    },
    {
      name: 'Vinicius Attilio',
      position: 'Founder & Marketing and Sales Director',
      pictureName: 'vinicius',
      linkedinLink: 'https://www.linkedin.com/in/viniciusattilio/',
      altText: this.$root.$tc('landing.altText.vini')
    }
  ]
}
